import { signOut } from 'aws-amplify/auth';

import { fetchAuthSession } from 'aws-amplify/src/auth';
import { Amplify } from 'aws-amplify';

import { Hub } from 'aws-amplify/utils';
import cookies from 'js-cookie';
import { AUTH_TOKEN, USER_ID } from '../constants-togather/auth';
import { COGNITO_IDENTITY_FIELDS } from '../constants-togather/cognito';
import config from '../../app-config';

import paths from '../constants/paths';
import { captureError } from './monitoring';

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolClientId: config.cognitoClientId,
        userPoolId: config.cognitoPoolId,
        loginWith: {
          oauth: {
            domain: config.cognitoPoolDomain,
            redirectSignIn: [paths.LOGIN],
            redirectSignOut: [paths.LOGOUT],
            scopes: ['email', 'profile', 'openid', 'phone'],
            responseType: 'code',
          },
          email: true, // Optional
        },
      },
    },
  },
  {
    ssr: true,
  }
);

// Used for development login
Hub.listen('auth', async ({ payload }) => {
  if (payload.event === 'signedIn') {
    try {
      const session = await fetchAuthSession();

      const accessToken = session.tokens?.accessToken?.toString();
      const identityDetails = session.tokens?.idToken?.payload;
      const userId = identityDetails[
        COGNITO_IDENTITY_FIELDS.DATABASE_ID
      ] as string;

      cookies.set(AUTH_TOKEN, accessToken, {
        ...(process.env.cookieSecure && {
          secure: process.env.cookieSecure === 'true',
        }),
        expires: 21,
      });
      cookies.set(USER_ID, userId);

      // Manual navigation to force a page reload, causing auth to be picked up
      window.location.href = paths.DASHBOARD;
    } catch (error) {
      await signOut();
      captureError(error, {
        tags: {
          source: 'auth',
        },
      });
    }
  }
});
