import {
  actionChannel,
  select,
  debounce,
  fork,
  all,
  takeLatest,
  take,
  call,
  put,
} from 'redux-saga/effects';
import querystring from 'query-string';
import React from 'react';

import { request } from './api';

import { toggleNotification } from '../actions/notifications';

import { getUserSupplierId } from '../selectors/supplier';

import {
  calendarsReceived,
  createReceived,
  updateReceived,
  deleteReceived,
  unavailableReceived,
  REQUESTED,
  CREATE_REQUESTED,
  UPDATE_REQUESTED,
  DELETE_REQUESTED,
  UNAVAILABLE_MARKED,
} from '../ducks/supplier-calendar';

import Link from '../components-togather/link';

export function* onRequest({ start, end, calendarId }) {
  const supplierId = yield select(getUserSupplierId);
  if (!supplierId) {
    return;
  }

  const getEvents = call(request, {
    url: `/suppliers/${supplierId}/calendars?${
      start && end
        ? querystring.stringify({
            start,
            end,
          })
        : ''
    }`,
  });

  if (!calendarId) {
    const events = yield getEvents;
    yield put(calendarsReceived(events));

    return;
  }

  const [events, singleEvent] = yield all([
    getEvents,
    call(request, {
      url: `/suppliers/${supplierId}/calendars/${calendarId}`,
    }),
  ]);

  yield put(
    calendarsReceived(
      singleEvent && !events.some(({ uuid }) => uuid === singleEvent.uuid)
        ? [...events, singleEvent]
        : events
    )
  );
}

export function* onCreate({ newEvent }) {
  const supplierId = yield select(getUserSupplierId);
  if (!supplierId) {
    return;
  }

  const { booking, uuid, ...data } = newEvent;

  const res = yield call(request, {
    method: 'POST',
    url: `/suppliers/${supplierId}/calendars`,
    data,
  });

  if (!res) {
    return;
  }

  yield put(createReceived(res));
}

export function* onUpdate({ updatedEvent }) {
  const supplierId = yield select(getUserSupplierId);
  if (!supplierId) {
    return;
  }

  const { booking, ...rest } = updatedEvent;
  const { uuid, ...data } = rest;

  yield call(request, {
    method: 'PATCH',
    url: `/suppliers/${supplierId}/calendars/${uuid}`,
    data,
  });

  yield put(updateReceived(rest));
}

export function* onDelete({ uuid }) {
  const supplierId = yield select(getUserSupplierId);
  if (!supplierId) {
    return;
  }

  yield call(request, {
    method: 'DELETE',
    url: `/suppliers/${supplierId}/calendars/${uuid}`,
  });

  yield put(deleteReceived(uuid));
}

function* markAsUnavailable(dateIso) {
  const supplierId = yield select(getUserSupplierId);
  if (!supplierId) {
    return;
  }

  const existing = yield call(request, {
    url: `/suppliers/${supplierId}/calendars?${querystring.stringify({
      start: dateIso,
      end: dateIso,
    })}`,
  });

  const alreadyUnavailable = existing.find(
    ({ state }) => state === 'unavailable'
  );
  if (alreadyUnavailable) {
    yield put(unavailableReceived(alreadyUnavailable.uuid));
    return;
  }

  const calendarEvent = yield call(request, {
    url: `/suppliers/${supplierId}/calendars`,
    method: 'POST',
    data: {
      state: 'unavailable',
      title: 'Unavailable',
      description: 'Marked as unavailable through modal',
      startTime: dateIso,
      endTime: dateIso,
    },
  });

  yield put(
    toggleNotification({
      text: (
        <span>
          You have marked yourself as unavailable for{' '}
          <Link
            style={{ color: 'inherit', textDecoration: 'underline' }}
            href={`/calendar/${calendarEvent.uuid}`}
          >
            this day
          </Link>
          .
        </span>
      ),
      isVisible: true,
      shouldDismiss: true,
      color: 'green',
    })
  );

  yield put(unavailableReceived(calendarEvent && calendarEvent.uuid));
}

export function* markUnavailability() {
  const channel = yield actionChannel(UNAVAILABLE_MARKED);
  while (true) {
    const { date } = yield take(channel);

    yield call(markAsUnavailable, date);
  }
}

export default function* calendar() {
  yield debounce(100, REQUESTED, onRequest);
  yield takeLatest(CREATE_REQUESTED, onCreate);
  yield takeLatest(UPDATE_REQUESTED, onUpdate);
  yield takeLatest(DELETE_REQUESTED, onDelete);
  yield fork(markUnavailability);
}
