import { useEffect } from 'react';
import { User } from '../types';
import { useUser } from './useUser';

export const useFreshchatIntegration = () => {
  const user: User | null = useUser();
  useEffect(() => {
    if (!user) {
      // console.warn('No user data available to pass to Fresh-chat.');
      return;
    }

    // console.log('User data available to pass to Fresh-chat', user);

    const {
      business_user: businessUser,
      email,
      firstName,
      id,
      lastName,
      phone,
      segment,
      type,
    } = user;

    const configureFreshchat = () => {
      if (window.fcWidget) {
        // Pass user data to Fresh-chat
        window.fcWidget.user.setProperties({
          businessUser,
          email,
          firstName,
          id,
          lastName,
          phone,
          segment,
          type,
        });

        window.fcWidget.user.get().then(() => {
          // console.log('Fresh-chat user properties', user);
        });
      } else {
        // console.log('Fresh-chat widget is not available.');
      }
    };

    configureFreshchat();
  }, [user]);
};
