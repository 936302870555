import { useEffect } from 'react';

export const useResourceCenterObserver = () => {
  useEffect(() => {
    const resourceCenterSelector = '#userpilot-resource-centre-frame';
    const freshChatSelector = '#fc_frame';

    // Function to hide the FreshChat beacon if the `fc-open` class is not present
    const updateFreshChatVisibility = () => {
      const freshChatFrame: HTMLIFrameElement | null = document.querySelector(
        freshChatSelector
      );
      const resourceCenter: HTMLIFrameElement | null = document.querySelector(
        resourceCenterSelector
      );

      if (freshChatFrame && resourceCenter) {
        if (freshChatFrame.classList.contains('fc-open')) {
          // Ensure chat panel is visible when open
          freshChatFrame.style.display = 'block';
        } else {
          // Hide the beacon when the panel is not open
          freshChatFrame.style.display = 'none';
        }
      }
    };

    // On FreshChat class change, update visibility
    const onFreshChatClassChange = () => updateFreshChatVisibility();

    // MutationObserver to monitor FreshChat class changes
    const freshChatObserver = new MutationObserver(onFreshChatClassChange);

    const startObservingFreshChat = () => {
      const freshChatFrame = document.querySelector(freshChatSelector);
      if (freshChatFrame) {
        freshChatObserver.observe(freshChatFrame, {
          attributes: true,
          attributeFilter: ['class'],
        });
      }
    };

    // MutationObserver to monitor resource center addition
    const onBodyChildAdded = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const resourceCenter = document.querySelector(resourceCenterSelector);
          if (resourceCenter) {
            updateFreshChatVisibility();
            startObservingFreshChat();
          }
        }
      });
    };

    // Start observing the DOM for the resource center iframe
    const bodyChildObserver = new MutationObserver(onBodyChildAdded);
    bodyChildObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Initial checks
    if (document.querySelector(resourceCenterSelector)) {
      updateFreshChatVisibility();
      startObservingFreshChat();
    }

    // Cleanup observers on unmount
    return () => {
      bodyChildObserver.disconnect();
      freshChatObserver.disconnect();
    };
  }, []);
};
