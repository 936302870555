import React from 'react';
import PropTypes from 'prop-types';

import { App as AppTogather } from './app-togather';

export default function App({ store }) {
  return <AppTogather store={store} />;
}

App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};
