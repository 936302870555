import { EventTypes } from 'redux-segment';
import cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import {
  getCurrentUser,
  signOut as amplifySignOut,
} from 'aws-amplify/src/auth';
import { httpService } from '../../index';
import { reduxFormError } from '../services/redux-form-error-service';
import stringToBase64 from '../libs/encoding-utils';
import { resetMessages } from '../actions/conversations';
import { VISITOR_ROLE } from '../constants/roles';
import config from '../../app-config';
import { history } from '../modules/history';
import { QUOTE_TEMPLATES_READ, RESET } from '../constants/action-types';
import { api } from '../modules/api';
import { ACCOUNT_CREATED } from '../constants/analytics';
import {
  AUTH_TOKEN,
  IS_IMPERSONATION_SESSION,
  USER_ID,
} from '../constants-togather/auth';

import {
  resetAnalytics,
  trackAlias,
  trackIdentity,
} from '../modules/analytics/analytics';
import {
  formatPhoneNumberWithGBCountryCode,
  formatPhoneNumberWithNoCountryCode,
} from '../libs/form-validation-utils';
import {
  isCognitoAuthentication,
  removeSSOLogoutFlag,
  setSSOLogoutFlag,
} from '../helpers/auth/cognito';
import paths from '../constants/paths';

export const storeToken = (token) => {
  cookies.set(AUTH_TOKEN, token, {
    ...(config.cookieSecure && { secure: config.cookieSecure }),
    expires: 21,
  });
};

export const SET_USER = 'platform/users/SET_USER';
const IDENTIFY_USER = 'platform/users/IDENTIFY_USER';
export const LOGOUT_USER = 'platform/users/LOGOUT_USER';
const ADD_FAVOURITE = 'platform/users/ADD_FAVOURITE';
const REMOVE_FAVOURITE = 'platform/users/REMOVE_FAVOURITE';
const STORE_FAVOURITE = 'platform/users/STORE_FAVOURITE';
const ALIAS_USER = 'platform/users/ALIAS_USER';
const TRACK_SIGN_UP = 'platform/users/TRACK_SIGN_UP';
const SET_USER_TOKEN = 'platform/users/SET_USER_TOKEN';
const UPDATE_USER_SUPPLIERS = 'platform/users/UPDATE_USER_SUPPLIERS';

const initialState = {
  type: VISITOR_ROLE.name,
  token: null,
  browsingHistory: [],
  favourites: [],
  storedFavourite: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_FAVOURITE:
      return {
        ...state,
        storedFavourite: action.payload.supplierId,
      };

    case ADD_FAVOURITE:
      return {
        ...state,
        storedFavourite: null,
        favourites: action.payload,
      };

    case REMOVE_FAVOURITE:
      return {
        ...state,
        favourites: state.favourites
          ? state.favourites.filter(
              (favourite) => action.payload.supplierId !== favourite.uuid
            )
          : [],
      };

    case SET_USER:
      return {
        ...state,
        ...action.payload,
        browsingHistory: action.payload.browsingHistory
          ? [...state.browsingHistory, action.payload.browsingHistory]
          : state.browsingHistory,
      };

    case UPDATE_USER_SUPPLIERS:
      return {
        ...state,
        suppliers: [{ ...state.suppliers[0], ...action.payload }],
      };

    case IDENTIFY_USER:
      return { ...state, ...action.payload };

    case LOGOUT_USER:
      return { ...initialState, browsingHistory: state.browsingHistory };

    case SET_USER_TOKEN:
      return { ...state, token: action.payload };

    default:
      return state;
  }
}

export const addFavouriteAction = (payload, supplierName, fromBrowser) => ({
  type: ADD_FAVOURITE,
  payload,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Favourite Added',
        properties: {
          Supplier: supplierName,
          '# of Suppliers Favourited': 1,
          SupplierBrowser: !!fromBrowser,
        },
      },
    },
  },
});

export const removeFavouriteAction = (supplierId, supplierName) => ({
  type: REMOVE_FAVOURITE,
  payload: { supplierId },
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Favourite Removed',
        properties: {
          Supplier: supplierName,
          '# of Suppliers Favourited': -1,
        },
      },
    },
  },
});

export const storeFavouriteAction = (supplierId) => ({
  type: STORE_FAVOURITE,
  payload: { supplierId },
});

export const setUserAction = (user) => ({ type: SET_USER, payload: user });

export const aliasUserAction = (userId) => ({
  type: ALIAS_USER,
  payload: userId,
  meta: {
    analytics: {
      eventType: EventTypes.alias,
      eventPayload: { userId },
    },
  },
});

export const trackUserSignUp = (userId) => ({
  type: TRACK_SIGN_UP,
  payload: userId,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: { event: ACCOUNT_CREATED, properties: { userId } },
    },
  },
});

export const identifyAction = (user) => ({
  type: IDENTIFY_USER,
  payload: user,
  meta: {
    analytics: {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: user.id,
        traits: {
          phone: formatPhoneNumberWithGBCountryCode(user.phone),
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          'User Type': user.type,
        },
      },
    },
  },
});

export const trackLoginAction = (user) => ({
  type: IDENTIFY_USER,
  payload: user,
  meta: {
    analytics: [
      {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: user.id,
          traits: {
            'First Log In Date': user.state.created,
            'Last Log In Date': user.state.lastLogin,
          },
        },
      },
      {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Logged in',
        },
      },
    ],
  },
});

export const logoutUserAction = () => ({
  type: LOGOUT_USER,
  payload: null,
  meta: { eventType: EventTypes.reset },
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token,
});

export const setUser = (user) => (dispatch) => {
  dispatch(setUserAction(user));

  if (user) {
    // Set Datadog user
    datadogRum.setUser({
      id: user?.id,
      email: user?.email,
      type: user?.type,
    });
  }
};

export const addFavourite = (
  supplierId,
  userId,
  supplierName,
  fromBrowser
) => async (dispatch) => {
  await httpService.request({
    path: `/users/${userId}/favourites/${supplierId}`,
    method: 'POST',
  });

  const payload = await httpService.request({
    path: `/users/${userId}/favourites`,
  });

  dispatch(addFavouriteAction(payload, supplierName, fromBrowser));
};

export const removeFavourite = (supplierId, userId, supplierName) => async (
  dispatch
) => {
  await httpService.request({
    path: `/users/${userId}/favourites/${supplierId}`,
    method: 'DELETE',
  });

  dispatch(removeFavouriteAction(supplierId, supplierName));
};

export const toggleFavourite = (supplierId, user, supplierName) => async (
  dispatch
) => {
  if (!user.id) {
    dispatch(storeFavouriteAction(supplierId));

    history.push(`/login?redirect=${history.location.pathname}`);
    return;
  }

  const isFavourited = user.favourites.some((f) => f && f.uuid === supplierId);

  const method = isFavourited ? removeFavourite : addFavourite;

  dispatch(method(supplierId, user.id, supplierName));
};

const login = (token, storedFavourite) => async (dispatch) => {
  let user = {};

  try {
    user = await httpService.request({
      path: '/users/me',
      token,
    });

    storeToken(token);
    removeSSOLogoutFlag();
    trackAlias(user.id);
    trackIdentity(user.id, {
      phone: formatPhoneNumberWithGBCountryCode(user.phone),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      'User Type': user.type,
      'Supplier ID': user?.suppliers?.[0]?.uuid,
      'Vertical Tier 1': user?.suppliers?.[0]?.category_tier1,
      'Vertical Tier 2': user.suppliers?.[0]?.tier2?.[0]?.name,
      State: user?.suppliers?.[0]?.state,
    });

    dispatch(setUser({ token, ...user }));
    dispatch(identifyAction(user));
    dispatch(trackLoginAction(user));

    if (storedFavourite) {
      dispatch(addFavourite(storedFavourite, user.id));
    }
  } catch (error) {
    return { error };
  }

  return user;
};

export const logInWithToken = (token) => async (dispatch) => {
  return await login(token)(dispatch);
};

export const logInWithEmailAddressAndPassword = (
  emailAddress,
  password,
  storedFavourite
) => async (dispatch) => {
  try {
    const { accessToken: token } = await httpService.request({
      path: '/users/login/',
      method: 'POST',
      headers: {
        Authorization: `Basic ${stringToBase64(
          `${emailAddress.toLowerCase()}:${password}`
        )}`,
      },
    });

    return await login(token, storedFavourite)(dispatch);
  } catch (error) {
    return { error };
  }
};

export const isCognitoUserPasswordAuth = async () => {
  try {
    const user = await getCurrentUser();
    return user?.signInDetails?.authFlowType === 'USER_PASSWORD_AUTH';
  } catch (error) {
    return false;
  }
};

export const logoutUser = (callEndpoint = true) => async (dispatch) => {
  const authToken = cookies.get(AUTH_TOKEN);
  resetAnalytics();

  if (isCognitoAuthentication(authToken)) {
    const shouldRedirect = await isCognitoUserPasswordAuth();
    await amplifySignOut();

    if (shouldRedirect) {
      window.location.href = paths.LOGOUT;
    }
  } else if (callEndpoint) {
    httpService.request({
      path: '/users/logout',
      method: 'GET',
    });
  }

  cookies.remove(AUTH_TOKEN, {
    ...(config.cookieSecure && { secure: config.cookieSecure }),
  });
  cookies.remove(USER_ID, {
    ...(config.cookieSecure && { secure: config.cookieSecure }),
  });
  cookies.remove(IS_IMPERSONATION_SESSION);

  dispatch(logoutUserAction());
  dispatch(resetMessages());
  dispatch({ type: RESET });

  // Clear supplier quote templates
  dispatch({ type: QUOTE_TEMPLATES_READ, payload: [] });
};

export const fetchUser = (token, extraParams) => async (dispatch) => {
  try {
    const user = await httpService.request({
      path: '/users/me',
      token,
    });

    const favourites = await httpService.request({
      path: `/users/${user.id}/favourites`,
      token,
    });

    storeToken(token);

    dispatch(setUser({ token, ...user, ...extraParams, favourites }));
    dispatch(identifyAction({ ...user }));
  } catch {
    dispatch(logoutUserAction());
  }
};

export const signUp = (data, storedFavourite, query) => async (dispatch) => {
  const body = {
    ...data,
    email: data.email.toLowerCase(),
    phone: formatPhoneNumberWithNoCountryCode(data.phone),
    sendWelcomeEmail: true,
  };

  const res = await api.post('/users/signup', body);

  if (res?.data?.accessToken) {
    const user = await httpService.request({
      path: '/users/me',
      token: res?.data?.accessToken,
    });

    storeToken(res?.data?.accessToken);
    trackAlias(user.id);
    trackIdentity(user.id, {
      phone: formatPhoneNumberWithGBCountryCode(user.phone),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      'User Type': user.type,
      'Supplier ID': user?.suppliers?.[0]?.uuid,
      'Vertical Tier 1': user?.suppliers?.[0]?.category_tier1,
      'Vertical Tier 2': user.suppliers?.[0]?.tier2?.[0]?.name,
      State: user?.suppliers?.[0]?.state,
    });

    dispatch(trackUserSignUp(user.id));
    dispatch(aliasUserAction(user.id));
    dispatch(
      setUser({ token: res?.data?.accessToken, toConfirm: true, ...user })
    );
    dispatch(identifyAction(user));

    if (query) {
      await addFavourite(
        query.supplierId,
        user.id,
        query.supplierName,
        true
      )(dispatch);
    }

    if (storedFavourite) {
      dispatch(addFavouriteAction(storedFavourite, user.id));
    }
  }
};

export const editUser = (userId, user) => async (dispatch) => {
  try {
    const payload = await httpService.request({
      path: `/users/${userId}`,
      method: 'PATCH',
      body: user,
    });

    dispatch(setUser(payload));
  } catch (err) {
    reduxFormError(err);
  }
};

// Not actions or thunks ????

export const editPassword = (userId, { currentPassword, newPassword }) => () =>
  httpService
    .request({
      path: `/users/${userId}/password`,
      method: 'POST',
      body: { currentPassword, newPassword },
    })
    .catch(reduxFormError);

export const sendResetPasswordEmail = (data) => () =>
  httpService
    .request({
      path: '/users/password/forgot',
      method: 'POST',
      body: { email: data?.email?.toLowerCase(), redirectUrl: data?.redirect },
    })
    .catch(reduxFormError);

export const getResetPasswordToken = (userId, token) => () =>
  httpService
    .request({
      path: `/users/${userId}/password/forgot/${token}`,
    })
    .catch(reduxFormError);

export const resetPassword = (userId, { token, newPassword }) => () =>
  httpService
    .request({
      path: `/users/${userId}/password/by_token`,
      method: 'POST',
      body: { token, newPassword },
    })
    .catch(reduxFormError);

export const getFavouriteSuppliers = (userId) =>
  httpService.request({
    path: `/users/${userId}/favourites/suppliers`,
    method: 'GET',
  });

export const updateUserSuppliers = (id, body) => (dispatch) => {
  return httpService
    .request({
      path: `/suppliers/${id}/updateInfo`,
      method: 'PUT',
      body,
    })
    .then((payload) => dispatch({ type: UPDATE_USER_SUPPLIERS, payload }));
};
