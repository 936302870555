import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import config from '../../app-config';

export const initDatadogLogging = () => {
  if (config.datadogClientToken) {
    datadogLogs.init({
      clientToken: config.datadogClientToken,
      site: 'datadoghq.eu',
      service: 'public-site',
      version: config.version,
      env: config.deployedEnvironment,
      silentMultipleInit: true,
    });
  }
};

export const initDatadogRum = (user) => {
  if (config.datadogApplicationId && config.datadogClientToken) {
    datadogRum.init({
      applicationId: config.datadogApplicationId,
      clientToken: config.datadogClientToken,
      site: 'datadoghq.eu',
      service: 'customer-dashboard',
      version: config.version,
      env: config.deployedEnvironment,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      silentMultipleInit: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        /https:\/\/[A-Za-z]*-*api-[A-Za-z]*\.togather\.com(?!.*socket\.io)/,
      ],
    });
    if (user?.id) {
      datadogRum.setUser({
        id: user?.id,
        email: user?.email,
        type: user?.type,
      });
    }

    datadogRum.startSessionReplayRecording();
  }
};

export const captureError = (error, context) =>
  datadogRum.addError(error, context);
